import React, { Suspense } from 'react';

import { useAppState, SuspenseLoader, SocketProvider } from '@jaksmok/lovelanguage-common';
import { CallSteps } from '@jaksmok/lovelanguage-common/dist/state';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ActiveCallStatus from 'components/ActiveCallStatus';
import { ErrorAlert } from 'components/AppAlert';
import AvailabilityStatus from 'components/AvailabilityStatus';
import Header from 'components/Header';
import IncomingCall from 'components/IncomingCall';
import UserAvatar from 'components/UserAvatar';
import useAvailabilityStatus, { AvailabilityStatusProvider } from 'hooks/useAvailabilityStatus';
import useVerifyProfile from 'hooks/useVerifyProfile';

import VideoApp from './App.Twilio';
import PrivateApp from './PrivateApp';

const useStyles = makeStyles((theme) => ({
  interpreter: {
    height: `calc(100vh - ${theme.headerHeight}px)`,
  },
  interpreterOnlineBG: {
    background: '#F2FCF8',
  },
  interpreterAwayBG: {
    background: '#F5F6F7',
  },
}));

const CallList = React.lazy(() => import('components/CallList'));

function InterpreterHome() {
  const classes = useStyles();
  const { user, error, setError, meeting, profile } = useAppState();
  const { isOnline, incomingCall } = useAvailabilityStatus();

  useVerifyProfile();

  return (
    <>
      <Header />
      <Grid container>
        <Grid item sm={4} md={3}>
          <Suspense fallback={<SuspenseLoader />}>
            <CallList />
          </Suspense>
        </Grid>
        <Grid
          item
          sm={8}
          md={9}
          className={clsx(classes.interpreter, isOnline ? classes.interpreterOnlineBG : classes.interpreterAwayBG)}
        >
          {meeting ? <ActiveCallStatus /> : <AvailabilityStatus />}

          <Box display="flex" alignItems="center" flexDirection="column" m={16}>
            <UserAvatar showBadge large user={user} active={isOnline} />
            <Box mt={2} textAlign="center">
              <Typography variant="h5">Welcome, {user.displayName || user.email}</Typography>
              <Typography variant="body2" color="textSecondary">
                Interpreter ID {profile?.interpreterId}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* App-wide Alerts */}
      <ErrorAlert dismissError={() => setError(null)} error={error} />

      {/* Incmoing Call Screen */}
      {incomingCall && <IncomingCall />}
    </>
  );
}

export default function App() {
  const { meeting, callStep } = useAppState();

  // Rating state handled by VideoApp
  if ((callStep === CallSteps.ratingStep || callStep === CallSteps.loading) && meeting) {
    return <VideoApp />;
  }

  return (
    <PrivateApp>
      <SocketProvider>
        <AvailabilityStatusProvider>
          <InterpreterHome />
        </AvailabilityStatusProvider>
      </SocketProvider>
    </PrivateApp>
  );
}
