import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 32,
    padding: 0,
    margin: 0,
  },
  switchBase: {
    padding: 1,
    top: 3,
    left: 3,
    '&$checked': {
      transform: 'translateX(27px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.success.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.success.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: '0px 2px 4px rgba(9, 28, 43, 0.08)',
  },
  track: {
    borderRadius: 17,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: 'rgba(9, 28, 43, 0.24)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      disableRipple
      focusVisibleClassName={classes.focusVisible}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default CustomSwitch;
