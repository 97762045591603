import React from 'react';

export default function CheckedOutlinedIcon() {
  return (
    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76 38C76 58.9868 58.9868 76 38 76C17.0132 76 0 58.9868 0 38C0 17.0132 17.0132 0 38 0C58.9868 0 76 17.0132 76 38ZM32.0094 50.6063C32.6597 51.0795 33.451 51.3347 34.2638 51.3333L34.2672 51.329C34.8469 51.3307 35.4192 51.2027 35.9397 50.955C36.4602 50.7074 36.9149 50.3467 37.2686 49.901L52.5558 30.5559C53.1637 29.7851 53.4317 28.8117 53.3009 27.8494C53.1701 26.8871 52.6512 26.0144 51.8581 25.4229L51.7753 25.5316L51.8559 25.4229L51.8548 25.4221C51.4617 25.1297 51.0131 24.9152 50.5346 24.791C50.0561 24.6668 49.557 24.6352 49.066 24.6982C48.5749 24.7611 48.1014 24.9173 47.6725 25.1578C47.2436 25.3984 46.8678 25.7185 46.5664 26.1L33.5695 42.5541L28.0694 38.4612C27.6761 38.1687 27.2273 37.9541 26.7486 37.8299C26.2699 37.7056 25.7707 37.6741 25.2794 37.737C24.7881 37.8 24.3144 37.9563 23.8854 38.1969C23.4563 38.4375 23.0803 38.7578 22.7788 39.1394C22.4773 39.521 22.2562 39.9566 22.1282 40.4211C22.0001 40.8857 21.9676 41.3701 22.0325 41.8469C22.0974 42.3236 22.2584 42.7833 22.5064 43.1996C22.7543 43.616 23.0844 43.9809 23.4776 44.2735L31.9691 50.5769L32.0094 50.6063Z"
        fill="#0088ED"
      />
    </svg>
  );
}
