import React, { useState } from 'react';

import { useAppState } from '@jaksmok/lovelanguage-common';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MailSendSuccessIcon from 'assets/Icons/MailSentIcon';
import Alert from 'components/Alert/LLAlert';
import Button from 'components/Buttons/LLButton';
import Input, { emailRegex } from 'components/Input/Input';
import LoginPageLayout from 'components/Layout/LoginPageLayout';
import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((_theme) => ({
  linkSent: {
    fontWeight: 600,
    fontSize: '24px',
    padding: '1rem 0',
  },
  checkYourEmail: {
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center',
    padding: '1rem 0',
  },
}));

export default function ForgetPasswordForm() {
  const classes = useStyles();
  const { control, handleSubmit } = useForm();
  const { sendPasswordResetEmail } = useAppState();
  const [authError, setAuthError] = useState(null);
  const [loading, setIsLoading] = useState(null);
  const [isSentResetLinkSuccess, setIsSentResetLinkSuccess] = useState(false);

  const onSubmit = ({ email }) => {
    setAuthError(null);
    setIsLoading(true);
    sendPasswordResetEmail(email)
      .then(() => {
        setIsSentResetLinkSuccess(true);
      })
      .catch((error) => {
        setAuthError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoginPageLayout fullScreen title={isSentResetLinkSuccess ? null : 'Forgot Password'}>
      {!isSentResetLinkSuccess ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              {authError && (
                <Box mb={2}>
                  <Alert type="error">{authError.message}</Alert>
                </Box>
              )}
              <Controller
                name="email"
                type="email"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error }, ref }) => (
                  <Input
                    fullWidth
                    variant="outlined"
                    value={value}
                    error={!!error}
                    control={control}
                    placeholder="Email"
                    helperText={error ? error.message : null}
                    inputRef={ref}
                    type="email"
                    onChange={onChange}
                  />
                )}
                rules={{ required: <Typography variant="body1">Email is required</Typography>, pattern: emailRegex }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box width="100%" marginY={2}>
                <Button fullWidth variant="contained" color="primary" type="submit" loading={loading}>
                  Send Reset Password Link
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginBottom={5}>
          <MailSendSuccessIcon />
          <Typography variant="h4" className={classes.linkSent}>
            Link Sent
          </Typography>
          <Typography variant="body1" className={classes.checkYourEmail}>
            Please go to your inbox and follow the instructions to reset your password.
          </Typography>
        </Box>
      )}
    </LoginPageLayout>
  );
}
