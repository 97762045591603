import { useRef, useState } from 'react';

import { Box, Paper, Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import { getTime } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    color: theme.palette.shadow.main,
    fontSize: 20,
  },
  phone: {
    fontWeight: 400,
    color: theme.palette.shadow.main,
    fontSize: 14,
    textAlign: 'center',
  },
  date: {
    marginTop: '.5rem',
    fontWeight: 500,
    color: 'rgba(9, 28, 43, 0.4)',
    fontSize: 14,
  },
  llName: {
    marginTop: '1.5rem',
    fontWeight: 400,
    color: 'rgba(0, 25, 40, 1)',
    fontSize: 16,
  },
  durationContainer: {
    marginTop: '1.5rem',
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    height: 'max-content',
    flexWrap: 'wrap',
  },
  duration: {
    margin: 0,
    fontWeight: 500,
    color: 'rgba(9, 28, 43, 0.4)',
    fontSize: 12,
    transform: 'translateY(-5px)',
  },
  slider: {
    width: '100%',
    '& .MuiSlider-rail': {
      height: 4,
    },
    '& .MuiSlider-track': {
      height: 4,
    },
    '& .MuiSlider-thumb': {
      transform: 'translateY(1px)',
    },
  },
  playPauseBtn: {
    transform: 'translateY(-15px)',
  },
}));

const AudioPlayback = ({ audioUrl, phoneNumber, date, llUser }) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioInfo, setAudioInfo] = useState({ currentTime: 0, duration: 0 });

  const audioRef = useRef();

  const dragHandler = (e, newValue) => {
    audioRef.current.currentTime = newValue;
    setAudioInfo({ ...audioInfo, currentTime: newValue });
  };

  const playOrPauseSongHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const timeUpdateHandler = (e) => {
    const current = e.target.currentTime;
    const duration = e.target.duration;

    setAudioInfo({
      ...audioInfo,
      currentTime: current,
      duration: duration,
    });
  };

  const timeEndHandler = (_e) => {
    setIsPlaying(false);
    setAudioInfo({
      ...audioInfo,
      currentTime: 0,
    });
  };

  return (
    <>
      <Paper
        elevation={0}
        component={Box}
        display="flex"
        width="100%"
        maxWidth={290}
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        pt="18px"
        pb="16px"
      >
        <Typography className={classes.title}>Voicemail</Typography>
        <div>
          <Typography className={classes.phone}>{phoneNumber}</Typography>
          <Typography className={classes.date}>{date}</Typography>
        </div>
        <Typography className={classes.llName}>To {llUser}</Typography>
        <div className={classes.durationContainer}>
          <Slider
            className={classes.slider}
            value={audioInfo.currentTime}
            max={audioInfo.duration || 0}
            step={0.01}
            min={0}
            onChange={dragHandler}
          />
          <p className={classes.duration}>{getTime(audioInfo.currentTime)}</p>
          <p className={classes.duration}>{getTime(audioInfo.duration)}</p>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
        <div role="button" className={classes.playPauseBtn} onClick={playOrPauseSongHandler}>
          {!isPlaying ? (
            <PlayCircleFilledIcon fontSize="large" cursor="pointer" color="primary" />
          ) : (
            <PauseCircleFilledIcon fontSize="large" cursor="pointer" color="error" />
          )}
        </div>
      </Paper>

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        src={audioUrl}
        onLoadedMetadata={timeUpdateHandler}
        onTimeUpdate={timeUpdateHandler}
        onEnded={timeEndHandler}
      />
    </>
  );
};

export default AudioPlayback;
