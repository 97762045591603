import React, { useEffect, useState, useCallback } from 'react';

import { LoaderScreen, useAppState, useAsyncMemo } from '@jaksmok/lovelanguage-common';
import { Typography } from '@material-ui/core';
import { SuccessAlert } from 'components/AppAlert';
import { useHistory, useParams } from 'react-router-dom';

import VideoMailService from '../../services/videomail';
import VideoRecordRoom from './VideoRecordRoom';

const VideoMailApp = () => {
  const history = useHistory();
  const { roomId } = useParams();
  const [uploadedMessage, setUploadedMessage] = useState(null);
  const { meeting, setMeeting } = useAppState();

  const [voiceMail, { error }] = useAsyncMemo(async () => {
    if (!meeting || meeting.status === 'COMPLETED') {
      throw new Error('Voicemail was already interpreted.');
    }

    const response = await VideoMailService.getVoiceMail(roomId);
    if (response.ok && response.data) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('There are no voicemails');
    }
    throw new Error('Something went wrong');
  }, [roomId]);

  useEffect(() => {
    if (error || uploadedMessage) {
      const redirectTimer = setTimeout(() => {
        setMeeting(null);
        history.replace('/');
      }, 3000);
      return () => {
        clearTimeout(redirectTimer);
      };
    }
  }, [error, history, uploadedMessage, setMeeting]);

  const handleOnUploaded = useCallback(
    (llUser) => {
      setUploadedMessage(`Successfully send to ${llUser.fullName}.`);
    },
    [setUploadedMessage]
  );

  if (voiceMail && meeting) {
    return (
      <>
        <SuccessAlert message={uploadedMessage} />
        <VideoRecordRoom roomId={roomId} voiceMail={voiceMail} onUploaded={handleOnUploaded} />
      </>
    );
  }

  return (
    <LoaderScreen>
      {error && (
        <>
          <Typography gutterBottom variant="h5" color="inherit">
            {error.message}
          </Typography>
          <Typography color="inherit">Redirecting...</Typography>
        </>
      )}
    </LoaderScreen>
  );
};

export default VideoMailApp;
