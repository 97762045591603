import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncCallback } from '@jaksmok/lovelanguage-common';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';

import UserService from '../../services/user';
import { changePasswordSchema } from '../../utils/formSchema';
import Button from '../Buttons/LLButton';
import Password from '../Input/Password';

const useStyles = makeStyles({
  form: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
});

const ChangePasswordForm = ({ onSuccess }) => {
  const classes = useStyles();
  const { control, handleSubmit, formState, setError } = useForm({ resolver: yupResolver(changePasswordSchema) });

  const [handleChangePassword] = useAsyncCallback(async (values) => {
    const { currentPassword, confirmPassword } = values;
    if (currentPassword && confirmPassword) {
      try {
        await UserService.updatePassword(currentPassword, confirmPassword);
        onSuccess();
      } catch (error) {
        if (error?.code === 'auth/wrong-password') {
          setError('currentPassword', { message: 'The old password you have entered is incorrect' });
        }
      }
    }
  }, []);

  return (
    <form className={classes.form} onSubmit={handleSubmit(handleChangePassword)}>
      <Box marginY="7px">
        <PasswordInputField
          control={control}
          placeholder="Current password"
          name="currentPassword"
          error={formState.errors?.currentPassword}
        />
      </Box>
      <Box marginY="7px">
        <PasswordInputField
          control={control}
          placeholder="New password"
          name="newPassword"
          error={formState.errors?.newPassword}
        />
      </Box>
      <Box marginY="7px">
        <PasswordInputField
          control={control}
          placeholder="Confirm password"
          name="confirmPassword"
          error={formState.errors?.confirmPassword}
        />
      </Box>

      <Box flexGrow={1} />

      <Button
        fullWidth
        type="submit"
        color="primary"
        variant="contained"
        loading={formState.isSubmitting}
        disabled={formState.isSubmitting}
      >
        Update
      </Button>

      <Box my="5px" />
    </form>
  );
};

export default ChangePasswordForm;

function PasswordInputField({ control, name, placeholder, error }) {
  return (
    <Password
      fullWidth
      name={name}
      variant="outlined"
      error={!!error}
      control={control}
      placeholder={placeholder}
      helperText={error?.message || null}
      type="text"
    />
  );
}
