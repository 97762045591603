import { useCallback, useEffect, useState } from 'react';

import {
  useAppState,
  useAsyncCallback,
  useInterval,
  useSubscription,
  StompState,
  useStompClient,
  logger as createLogger,
  useWakeEvent,
} from '@jaksmok/lovelanguage-common';
import { useHistory } from 'react-router-dom';

import { INTERPRETER_SOCKET_RECONNECTION_DELAY } from '../constants';

const logger = createLogger.withTag('roomQueue');

export default function useRoomQueue({ isOnline, setStatusOnline }) {
  const { user, setError, refreshIdToken, meeting, setNotification } = useAppState();
  const history = useHistory();
  const stompClient = useStompClient();
  const INTERPRETER_SOCKET_RECONNECTION_DELAY_IN_MS = INTERPRETER_SOCKET_RECONNECTION_DELAY * 60 * 1000;
  const [incomingCall, _setIncomingCall] = useState(null);
  const setIncomingCall = useCallback((room) => _setIncomingCall(room), []);
  const [stompReconnectDelay, setStompReconnectDelay] = useState(null);
  const [queueCycle, setCycle] = useState(0);
  const [retryCount, setRetry] = useState(0);

  const [deactivateStomp] = useAsyncCallback(async () => {
    if (!stompClient || stompClient?.state !== StompState.ACTIVE) return Promise.resolve();
    logger.log('DEACTIVATE');

    await stompClient.deactivate();

    setStompReconnectDelay(null);

    return Promise.resolve();
  }, [stompClient]);

  const [activateStomp] = useAsyncCallback(async () => {
    if (!stompClient || stompClient?.state === StompState.ACTIVE) return Promise.resolve();
    logger.log('ACTIVATE', { queueCycle, retryCount });

    stompClient.onStompError = function (frame) {
      logger.error('ERROR', frame);
      if (retryCount < 5) {
        refreshIdToken();
        setStompReconnectDelay(3000);
        setError(new Error('Communication error! Reconnecting in 3 seconds...'));
        setRetry(retryCount + 1);
      } else {
        setStatusOnline(false);
        deactivateStomp();
        setError(new Error('Socket timeout. You are now disconnected.'));
      }
    };

    // We dont use built-in "reconnectDelay" config in order to take control of reconnections
    setStompReconnectDelay(INTERPRETER_SOCKET_RECONNECTION_DELAY_IN_MS);

    setCycle(queueCycle + 1);

    return stompClient.activate();
  }, [stompClient, setCycle, queueCycle, retryCount, setRetry]);

  // Socket auto-reconnection handler
  const [reconnectStomp] = useAsyncCallback(async () => {
    logger.log('RECONNECTING', { queueCycle, stompReconnectDelay });
    await deactivateStomp();
  }, [deactivateStomp, queueCycle]);

  // Reconnect periodically
  useInterval(reconnectStomp, isOnline ? stompReconnectDelay : null);

  // Reconnect after inactivity (user's PC waked-up from sleep)
  useWakeEvent(reconnectStomp, isOnline);

  // Handle incoming messages from socket connection
  const handleOnSocketMessage = useCallback(
    (message) => {
      let roomData = message.body;

      try {
        roomData = JSON.parse(message.body);
        logger.debug('MESSAGE RECEIEVED', roomData);
      } catch (e) {
        logger.error('SOCKET PARSE ERROR', e);
      }

      if (roomData.id && !incomingCall) {
        setIncomingCall(roomData);
      }
    },
    [incomingCall, setIncomingCall]
  );

  const handleOnQueueCanceled = useCallback(
    (message) => {
      logger.debug('QUEUE CANCELLED', message?.body);
      setIncomingCall(null);
    },
    [setIncomingCall]
  );

  const handleEmergencyCall = useCallback(
    (message) => {
      let roomData = message.body;

      try {
        roomData = JSON.parse(message.body);
        logger.debug('EMERGENCY_CALL_RECEIEVED', roomData);
      } catch (e) {
        logger.error('EMERGENCY_CALL_ERROR', e);
      }
      if (roomData?.fromInterpreter?.id === user.uid) return;
      if (roomData?.roomId) history.push(`/room/${roomData.roomId}`);
      if (roomData?.reason) {
        setNotification({
          headline: 'Help Reason',
          message: roomData.reason,
          autoHideDuration: 5000,
        });
      }
    },
    [history, setNotification, user.uid]
  );

  // Automatically activate Stomp if preconditions met
  useEffect(() => {
    if (!stompReconnectDelay && !meeting && isOnline) {
      logger.log('ACTIVATING, cycle:', queueCycle);
      activateStomp();
    }

    // Interpreter went OFFLINE or logged-out
    if (stompClient?.state === StompState.ACTIVE && (!isOnline || !user)) {
      logger.log('User went offline, DEACTIVATING', { queueCycle });
      deactivateStomp().then(() => {
        setCycle(1); //reset counter
      });
    }
  }, [activateStomp, deactivateStomp, isOnline, meeting, queueCycle, stompReconnectDelay, user, stompClient]);

  // Subscribe to the queue matching events
  useSubscription('/user/topic/queue-matched', handleOnSocketMessage);

  // Subscribe to the queue cancelation events
  useSubscription('/user/topic/queue-cancelled', handleOnQueueCanceled);

  // Subscribe to the queue emergency call events
  useSubscription('/user/topic/room-request-help-updated', handleEmergencyCall);

  return { activateStomp, deactivateStomp, reconnectStomp, stompClient, setIncomingCall, incomingCall };
}
