import React, { useState } from 'react';

import { logger, useInterval } from '@jaksmok/lovelanguage-common';
import { getSecondsFromExpiry } from '@jaksmok/lovelanguage-common/dist/utils';
import { Grid, Box, Typography } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import Switch from 'components/Switch';
import { formatDistance } from 'date-fns';
import useAvailabilityStatus from 'hooks/useAvailabilityStatus';

import PopoverDropdown from './Popover/PopoverDropdown';

export default function AvailabilityStatus() {
  const { isOnline, handleStatusChange, pending, breakTime } = useAvailabilityStatus();
  const breakEndDate = breakTime?.breakEndDate || '';
  const secondsDistanceToEnd = breakEndDate ? getSecondsFromExpiry(breakEndDate) : -1;
  const [humanReadableDistance, setFormatedDistance] = useState('-');

  useInterval(
    () => {
      const distanceToEnd = breakEndDate
        ? formatDistance(new Date(), breakEndDate, {
            includeSeconds: true,
          })
        : '-';

      setFormatedDistance(distanceToEnd);
    },
    secondsDistanceToEnd > 0 ? 1000 : null
  );

  // Set interpreter to online on break ends
  useInterval(
    () => {
      logger.debug('Break time is done!');
      handleStatusChange(true);
      setFormatedDistance('-');
    },
    secondsDistanceToEnd > 0 ? secondsDistanceToEnd * 1000 : null
  );

  const showBreakTimeStatus = secondsDistanceToEnd > 0 && !isOnline;

  const BreakTimeIndicator = (
    <Box display="flex" alignItems="center" justifyContent="center" p={1}>
      <TimerIcon color="primary" />
    </Box>
  );

  return (
    <Box borderBottom="1px solid rgba(9, 28, 43, 0.08)" p={3}>
      <Grid container>
        {/*  */}
        <Grid item xs={!showBreakTimeStatus ? 11 : 10}>
          <Box>
            <Typography>
              <strong>{isOnline ? 'Online' : 'Offline'}</strong>
            </Typography>
            <Typography>
              {isOnline
                ? 'You will be notified if there are available calls for you to take.'
                : 'You will not receive any incoming calls.'}
            </Typography>
          </Box>
        </Grid>

        {/*  */}
        {showBreakTimeStatus && (
          <Grid item xs={1}>
            <PopoverDropdown openOnMount id="break-time-popover" label={BreakTimeIndicator}>
              <Typography variant="body2">Your break ends in {humanReadableDistance}</Typography>
            </PopoverDropdown>
          </Grid>
        )}

        {/*  */}
        <Grid item xs={1}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" pt={1}>
            <Switch
              disabled={pending}
              checked={isOnline}
              inputProps={{ 'aria-label': 'online status switch' }}
              onChange={handleStatusChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
