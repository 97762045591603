import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneInput, useAppState, useAsyncCallback } from '@jaksmok/lovelanguage-common';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from 'components/Alert/LLAlert';
import Button from 'components/Buttons/LLButton';
import Input from 'components/Input/Input';
import Password from 'components/Input/Password';
import LoginPageLayout from 'components/Layout/LoginPageLayout';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import { signUpSchema } from '../../utils/formSchema';

YupPassword(yup);

const useStyles = makeStyles((theme) => ({
  signUpRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '50px',
      background: theme.palette.background.whiteSmoke,
    },
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const { control, handleSubmit, formState } = useForm({ resolver: yupResolver(signUpSchema) });
  const { signUp } = useAppState();
  const history = useHistory();
  const [authError, setAuthError] = useState(null);

  const [handleSignUp] = useAsyncCallback(
    async ({ name, email, password, phoneNumbers }) => {
      setAuthError(null);
      try {
        await signUp(name, email, password, phoneNumbers);
        history.replace({ pathname: '/' });
      } catch (error) {
        setAuthError(error);
      }
    },
    [history, signUp]
  );

  return (
    <LoginPageLayout isFullScreen title="Sign Up As An Interpreter" classNames={classes.signUpRoot}>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <Grid container>
          <Box item component={Grid} paddingY={1} xs={12}>
            {authError && (
              <Box mb={2}>
                <Alert type="error">{authError.message}</Alert>
              </Box>
            )}

            <Input
              fullWidth
              defaultValue=""
              name="name"
              variant="outlined"
              control={control}
              error={!!formState.errors?.name}
              helperText={formState.errors?.name?.message || null}
              placeholder="Full name"
              type="text"
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <PhoneInput
              fullWidth
              defaultValue=""
              name="phoneNumbers"
              control={control}
              variant="outlined"
              error={!!formState.errors?.phoneNumbers}
              helperText={formState.errors?.phoneNumbers?.message || null}
              placeholder="Phone number"
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <Input
              fullWidth
              defaultValue=""
              name="email"
              variant="outlined"
              control={control}
              error={!!formState.errors?.name}
              helperText={formState.errors?.name?.message || null}
              placeholder="Email address"
              type="text"
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <Password
              fullWidth
              variant="outlined"
              name="password"
              type="password"
              defaultValue=""
              error={!!formState.errors?.password}
              helperText={formState.errors?.password?.message || null}
              control={control}
              placeholder="Password"
              inputProps={{ autoComplete: 'new-password', spellcheck: false }}
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <Password
              fullWidth
              variant="outlined"
              name="confirmPassword"
              type="password"
              defaultValue=""
              error={!!formState.errors?.confirmPassword}
              helperText={formState.errors?.confirmPassword?.message || null}
              control={control}
              placeholder="Password"
              inputProps={{ autoComplete: 'new-password', spellcheck: false }}
            />
          </Box>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-around" width="100%" marginY={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={formState.isSubmitting}
                disabled={formState.isSubmitting}
              >
                Sign Up
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </LoginPageLayout>
  );
}
