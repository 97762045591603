import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    background: theme.palette.background.dark,
  },
  header: {
    background: `${theme.palette.background.light} !important`,
  },
  content: {
    width: '100%',
    flex: 1,
  },
}));

const IntroContainer = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header className={classes.header} />

      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

export default IntroContainer;
