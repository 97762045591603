import { createTheme } from '@material-ui/core/styles';

const palette = createTheme().palette;

const htmlFontSize = 16;
const fontSize = 14;
const systemFont = {
  fontFamily: 'system',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    -apple-system,
    BlinkMacSystemFont,
    local(".SFNSText-Light"),
    local(".HelveticaNeueDeskInterface-Light"),
    local(".LucidaGrandeUI"),
    local("Ubuntu Light"),
    local("Segoe UI Light"),
    local("Roboto-Light"),
    local("DroidSans"),
    local("Tahoma")
  `,
};

// Custom Theme Object
const theme = {
  palette: {},
  typography: {},
  overrides: {},

  // Custom variables
  headerHeight: 56,

  // Custom functions
  pxToRem: (size) => `${(size / htmlFontSize) * (fontSize / 14)}rem`,
};

// Color Palette
theme.palette = {
  primary: palette.augmentColor({
    main: '#0088ED',
    light: '#30B9EF',
    dark: '#001928',
  }),
  danger: palette.augmentColor(
    {
      main: '#EB5757',
    },
    500
  ),
  success: palette.augmentColor(
    {
      main: '#27AE60',
    },
    500
  ),
  background: {
    white: '#fff',
    light: '#e5e5e5',
    dark: '#091c2b',
    whiteSmoke: '#F5F5F5',
  },
  shadow: {
    main: '0px 4px 8px rgba(9, 28, 43, 0.08)',
  },
  passive: '#C5C5C5',
};

theme.palette.text = {
  primary: theme.palette.primary.dark,
  secondary: `${theme.palette.primary.dark}80`, // 80 = %50 alpha
};

// Typography
theme.typography = {
  fontFamily: 'system, sans-serif',
  htmlFontSize: 18,
  fontSize: 16,
  button: {
    textTransform: 'none',
  },
  body2: {
    fontSize: 14,
  },
  fontWeightBold: 600,
  h6: {
    fontWeight: 600,
  },
  h5: {
    fontWeight: 600,
  },
  h4: {
    fontWeight: 600,
  },
};

// Overrides
theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [systemFont],
    },
  },
  MuiInputBase: {
    root: {
      background: 'rgba(9, 28, 43, 0.02)',
    },
  },
  MuiOutlinedInput: {
    root: { border: 'none' },
    notchedOutline: {
      borderColor: 'rgba(9, 28, 43, 0.08)',
      borderWidth: '1px',
    },
  },
  MuiListSubheader: {
    root: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 3,
      letterSpacing: 0,
      marginTop: '1rem',
    },
  },
  MuiButton: {
    root: {
      borderRadius: '4px',
      textTransform: 'none',
      fontSize: '0.9rem',
    },
    text: {
      padding: '6px 14px',
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: '#30B9EF',
        color: '#FFF',
      },
    },
    outlinedPrimary: {
      border: '2px solid #027AC5',
      '&:hover': {
        border: '2px solid rgb(1, 85, 137)',
        backgroundColor: '#30B9EF',
        color: '#FFF',
      },
    },
    startIcon: {
      marginRight: '6px',
    },
  },
};

// Default Components Props
theme.props = {
  MuiButtonBase: {
    disableRipple: true,
  },
};

export default createTheme(theme);
