import React, { useEffect, useState } from 'react';

import { useAppState, LoaderScreen } from '@jaksmok/lovelanguage-common';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function PrivateApp({ children }) {
  const history = useHistory();
  const { isUserInterpreter, signOut } = useAppState();
  const [showApp, setAppVisibility] = useState(false);
  const authReady = isUserInterpreter !== null;

  useEffect(() => {
    if (!authReady) return;

    setAppVisibility(isUserInterpreter);
    if (isUserInterpreter) return;

    const redirectTimer = setTimeout(() => {
      signOut();
      history.replace('/');
    }, 3000);
    return () => clearTimeout(redirectTimer);
  }, [authReady, history, isUserInterpreter, signOut]);

  if (!showApp && authReady) {
    return (
      <LoaderScreen>
        <Typography gutterBottom variant="h5" color="inherit">
          You don&rsquo;t have required permissions to display this page.
        </Typography>
        <Typography color="inherit">Redirecting...</Typography>
      </LoaderScreen>
    );
  }

  if (!showApp) return null;

  return children;
}
