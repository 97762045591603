import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const Spinner = withStyles({
  root: {
    marginRight: 10,
    color: '#fff',
    margin: '4px 0',
  },
})(({ classes }) => <CircularProgress className={classes.root} size={20} />);

const ButtonStyles = makeStyles((_theme) => ({
  root: {
    fontWeight: '600',
    fontSize: '16px',
    '&.MuiButton-containedPrimary': {
      '&:hover': {
        backgroundColor: '#30B9EF',
        color: '#FFF',
      },
    },
  },
}));
export default function LLButton({ loading, children, buttonClasses, ...props }) {
  const classes = ButtonStyles();
  return (
    <Button className={clsx(classes.root, buttonClasses)} {...props}>
      {loading ? <Spinner /> : children}
    </Button>
  );
}
