import React from 'react';

import MuiAvatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/styles/makeStyles';
import UserIcon from 'assets/Icons/UserIcon';
import clsx from 'clsx';
import { getInitials } from 'utils/helpers';

import StyledBadge from './Badge';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: 'white',
    backgroundColor: palette.primary.main,
  },
  large: {
    width: 124,
    height: 124,
    '& > svg': {
      width: 'auto',
      height: 50,
      fill: palette.primary.main,
    },
  },
}));

function Avatar({ user, large }) {
  const classes = useStyles();
  const { displayName, photoURL } = user;

  return photoURL ? (
    <MuiAvatar src={photoURL} className={clsx(large && classes.large)} />
  ) : (
    <MuiAvatar className={clsx(classes.root, large && classes.large)}>
      {displayName ? getInitials(displayName) : <UserIcon />}
    </MuiAvatar>
  );
}

export default function UserAvatar({ user, showBadge, large, active }) {
  return (
    <>
      {showBadge ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          color={active ? 'active' : 'passive'}
          dotSize="large"
        >
          <Avatar user={user} large={large} />
        </StyledBadge>
      ) : (
        <Avatar user={user} large={large} />
      )}
    </>
  );
}

UserAvatar.defaultProps = {
  user: {},
  showBadge: false,
  large: false,
  active: false,
};
