import React from 'react';

import {
  ClosedCaption,
  Room,
  ReconnectingNotification,
  RecordingNotifications,
  MenuBar,
  MobileTopMenuBar,
  PreJoinScreens,
  ErrorDialog,
  ChatProvider,
  TranscriptsProvider,
  VideoProvider,
  DialPadProvider,
  theme as videoTheme,
  useHeight,
  useRoomState,
  useAppState,
  useConnectionOptions,
  SocketProvider,
} from '@jaksmok/lovelanguage-common';
import { MuiThemeProvider, styled } from '@material-ui/core/styles';

import IntroContainer from './components/IntroContainer/IntroContainer';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: theme.palette.background.dark,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        <IntroContainer>
          <PreJoinScreens />
        </IntroContainer>
      ) : (
        <DialPadProvider>
          <TranscriptsProvider>
            <Main>
              <ReconnectingNotification />
              <RecordingNotifications />
              <MobileTopMenuBar />
              <ClosedCaption />
              <Room />
              <MenuBar />
            </Main>
          </TranscriptsProvider>
        </DialPadProvider>
      )}
    </Container>
  );
}

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <MuiThemeProvider theme={videoTheme}>
      <SocketProvider>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <ChatProvider>
            <App />
          </ChatProvider>
        </VideoProvider>
      </SocketProvider>
    </MuiThemeProvider>
  );
};

export default VideoApp;
