import React, { useEffect, useImperativeHandle, useState } from 'react';

import { logger } from '@jaksmok/lovelanguage-common';
import useLocalTracks from '@jaksmok/lovelanguage-common/dist/components/VideoProvider/useLocalTracks/useLocalTracks';
import VideoTrack from '@jaksmok/lovelanguage-common/dist/components/VideoTrack/VideoTrack';
import useStopwatch from '@jaksmok/lovelanguage-common/dist/hooks/useStopwatch';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import useMediaRecorder from '../../hooks/useMediaRecorder';

const useStyles = makeStyles(() => ({
  noVideoSourceText: {
    paddingLeft: '35%',
  },
  timer: {
    position: 'absolute',
    right: 10,
    zIndex: 1000,
    background: 'rgba(9, 28, 43, 0.16)',
    padding: '.5rem 1rem',
    borderRadius: 2,
  },
}));

const VideoRecorder = ({ onAvailable }, ref) => {
  const {
    start: startTimer,
    reset: resetTimer,
    humanReadable,
    isRunning: isCountingTimer,
  } = useStopwatch({ autoStart: false });

  const location = useLocation();
  const [mediaError, setMediaError] = useState();
  const { localTracks, getLocalVideoTrack, removeLocalVideoTrack } = useLocalTracks();
  const { recordedVideo, recordedVideoTrack, getMediaStream, startRecording, stopRecording } = useMediaRecorder();
  const videoTrack = localTracks.find((track) => track.name.includes('camera'));
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  // message was caught from the socket
  const data = location.state;

  useEffect(() => {
    if (loaded) return;

    if (!mediaError) {
      getLocalVideoTrack().catch((error) => {
        setMediaError(error);
      });
    }

    getMediaStream().catch((error) => {
      logger.error('navigator.getUserMedia error:', error);
    });

    setLoaded(true);
  }, [loaded, data, mediaError, getLocalVideoTrack, getMediaStream]);

  useEffect(() => {
    if (recordedVideo && recordedVideoTrack) {
      onAvailable && onAvailable(recordedVideo, recordedVideoTrack);
    }
  }, [onAvailable, recordedVideo, recordedVideoTrack]);

  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        startTimer();
        startRecording();
      },
      stop: () => {
        resetTimer();
        removeLocalVideoTrack();
        stopRecording();
      },
    }),
    [removeLocalVideoTrack, resetTimer, startRecording, startTimer, stopRecording]
  );

  if (videoTrack) {
    return (
      <Box
        width="70%"
        borderRadius="8px"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        margin="auto"
        position="relative"
      >
        {isCountingTimer && <p className={classes.timer}>{humanReadable}</p>}
        <VideoTrack isLocal track={videoTrack} />
      </Box>
    );
  }

  return <Typography className={classes.noVideoSourceText}>Check permissions and turning on your camera...</Typography>;
};

export default React.forwardRef(VideoRecorder);
