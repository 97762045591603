import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'assets/Logo/360Live-192.png';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.whiteSmoke,
  },
  fullScreen: {
    height: '100vh',
    width: '100%',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    textAlign: 'center',
    width: '126px',
  },
  paper: {
    height: 'auto',
    width: '390px',
    borderRadius: '8px',
    boxShadow: theme.palette.shadow.main,
    background: theme.palette.background.white,
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: '600',
    fontSize: '24px',
  },
}));

export default function LoginPageLayout({ fullScreen, title, children, footer, classNames, ...props }) {
  const classes = useStyles();
  return (
    <section
      className={clsx(classes.root, classes.center, classNames, { [classes.fullScreen]: fullScreen })}
      {...props}
    >
      <Box paddingTop="40px">
        <img src={logo} alt="360Live logo" className={classes.logo} />
      </Box>
      <div className={clsx(classes.paper, classes.center)}>
        <Box mb={2} display="flex" alignItems="center">
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        {children}
      </div>
      {footer}
    </section>
  );
}
