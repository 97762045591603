import React from 'react';

import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from 'assets/Icons/ErrorIcon';
import clsx from 'clsx';

const useStyles = makeStyles((_theme) => ({
  root: {
    width: '320px',
    height: '48px',
    padding: '12px 6px',
    color: '#000',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  errorAlertBGColor: {
    backgroundColor: '#FEF1F1',
  },
}));

export default function LLAlert({ type, children, style }) {
  const classes = useStyles();
  return (
    <>
      {type === 'error' && (
        <div className={clsx(classes.root, classes.errorAlertBGColor)} style={style}>
          <Box component="span" marginX={1}>
            <ErrorIcon />
          </Box>
          <Typography variant="body2">{children}</Typography>
        </div>
      )}
    </>
  );
}
