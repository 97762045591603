import Fab, { styles } from '@material-ui/core/Fab/Fab';
import withStyles from '@material-ui/styles/withStyles';
import clsx from 'clsx';

const StyledFab = withStyles((theme) => ({
  ...styles(theme),
  root: {
    ...styles(theme).root,
    width: theme.spacing(10),
    height: theme.spacing(10),
    '&::after': {
      visibility: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      borderWidth: '2px',
      borderStyle: 'solid',
      content: '""',
    },
    '&:hover': {
      '&::after': {
        visibility: 'visible',
        animation: 'fabpulse 1.0s infinite ease-in-out',
      },
    },
  },
  primary: {
    ...styles(theme).primary,
    boxShadow: `0px 10px 15px ${theme.palette.primary.main}3d`,
    '&:hover': {
      ...styles(theme).primary['&:hover'],
      '&::after': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  danger: {
    boxShadow: `0px 10px 15px ${theme.palette.danger.main}3d`,
    color: theme.palette.danger.contrastText,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.danger.main,
      },
      '&::after': {
        borderColor: theme.palette.danger.main,
      },
    },
  },
  '@global': {
    '@keyframes fabpulse': {
      '0%': {
        opacity: 1,
        transform: 'scale(.8)',
      },
      '100%': {
        opacity: 0,
        transform: 'scale(1.4)',
      },
    },
  },
}))(({ classes, color, className, ...other }) => {
  return (
    <Fab
      className={clsx(
        classes.root,
        {
          [classes.primary]: color === 'primary',
          [classes.secondary]: color === 'secondary',
          [classes.danger]: color === 'danger',
        },
        className
      )}
      {...other}
    />
  );
});

export default StyledFab;
