import Badge from '@material-ui/core/Badge';
import withStyles from '@material-ui/styles/withStyles';
import { styledBy } from 'utils/helpers';

const StyledBadge = withStyles(({ palette }) => ({
  '@global': {
    '@keyframes activebadge': {
      '0%': {
        opacity: 1,
        transform: 'scale(.8)',
      },
      '100%': {
        opacity: 0,
        transform: 'scale(2.4)',
      },
    },
  },
  badge: {
    backgroundColor: styledBy('color', {
      active: palette.success.main,
      passive: palette.passive,
    }),
    color: styledBy('color', {
      active: palette.success.main,
      passive: palette.passive,
    }),
    boxShadow: `0 0 0 4px white`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: styledBy('color', {
        active: `activebadge 1.2s infinite ease-in-out`,
        passive: '',
      }),
      border: '1px solid currentColor',
      content: '""',
    },
  },
  dot: {
    height: styledBy('dotSize', {
      default: 8,
      large: 20,
    }),
    minWidth: styledBy('dotSize', {
      default: 8,
      large: 20,
    }),
    borderRadius: styledBy('dotSize', {
      default: 4,
      large: 10,
    }),
  },
  // eslint-disable-next-line no-unused-vars
}))(({ classes, color, dotSize, ...other }) => {
  return <Badge classes={{ badge: classes.badge, dot: classes.dot }} {...other} />;
});

export default StyledBadge;
