import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  useAppState,
  useAsyncCallback,
  InterpreterService,
  InterpreterStatus,
  RoomService,
} from '@jaksmok/lovelanguage-common';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';

import VideoMailService from '../../services/videomail';
import AudioPlayback from './AudioPlayback';
import VideoRecorder from './VideoRecorder';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    background: palette.background.dark,
    color: '#fff',
  },
  recordBtn: {
    marginBottom: '1.25rem',
    minWidth: 120,
  },
  progress: {
    color: 'white',
  },
}));

export default function VideoRecordRoom({ roomId, voiceMail, onUploaded }) {
  const { meeting } = useAppState();
  const { nonLLUser, llUser, createdDate } = meeting;
  const formattedDate = useMemo(() => format(new Date(createdDate), 'Pp'), [createdDate]);

  const classes = useStyles();
  const recorderRef = useRef(null);
  const [recordedVideo, setRecordedVideo] = useState();
  const [recordedVideoTrack, setRecordedVideoTrack] = useState();
  const [capturing, setCapturing] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const startCapture = useCallback(() => {
    setCapturing(true);
    recorderRef.current?.start();
  }, []);

  const stopCapture = useCallback(() => {
    setCapturing(false);
    recorderRef.current?.stop();
  }, []);

  const recapture = useCallback(() => {
    setRecordedVideo(null);
    setRecordedVideoTrack(null);
  }, []);

  const [handleUpload, { pending: uploadingRecord }] = useAsyncCallback(async () => {
    if (recordedVideo) {
      await VideoMailService.upload(roomId, recordedVideo);
      // Set interpreter status to AWAY
      await InterpreterService.updateStatus(InterpreterStatus.AWAY);
      // Let the backend know that interpreter left
      await RoomService.endMeeting(roomId);

      setUploaded(true);
      onUploaded && onUploaded(llUser);
    }
  }, [onUploaded, roomId, recordedVideo, llUser, setUploaded]);

  const handleOnRecordAvailable = useCallback((recordedVideo, recordedVideoTrack) => {
    setRecordedVideo(recordedVideo);
    setRecordedVideoTrack(recordedVideoTrack);
  }, []);

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-evenly">
        <Grid item xs={4}>
          {voiceMail && (
            <Box display="flex" justifyContent="flex-end" margin="auto">
              <AudioPlayback
                audioUrl={voiceMail}
                phoneNumber={nonLLUser.fullName}
                llUser={llUser.fullName}
                date={formattedDate}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={8}>
          {recordedVideoTrack && !capturing && (
            <Box width="70%" borderRadius="8px" overflow="hidden" display="flex" justifyContent="center" margin="auto">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video controls style={{ width: '100%', height: '100%' }} src={recordedVideoTrack} />
            </Box>
          )}
          {!recordedVideoTrack && <VideoRecorder ref={recorderRef} onAvailable={handleOnRecordAvailable} />}
          <Box display="flex" width="100%" justifyContent="center">
            <Box mt={4} display="flex" justifyContent="space-between">
              <Button
                variant={!recordedVideoTrack ? 'contained' : 'text'}
                color={!capturing ? 'primary' : 'secondary'}
                className={classes.recordBtn}
                disabled={uploadingRecord || uploaded}
                onClick={capturing ? stopCapture : recordedVideoTrack ? recapture : startCapture}
              >
                {recordedVideoTrack ? 'Record Again' : (capturing ? 'Stop' : 'Start') + ' Recording'}
              </Button>

              {recordedVideoTrack && !capturing && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.recordBtn}
                  style={{ marginLeft: '1rem' }}
                  disabled={uploadingRecord || uploaded}
                  onClick={handleUpload}
                >
                  {!uploadingRecord ? (
                    'Send To User'
                  ) : (
                    <CircularProgress size={25} variant="indeterminate" className={classes.progress} />
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
