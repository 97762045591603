import React from 'react';

import { useAppState } from '@jaksmok/lovelanguage-common';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ children, ...rest }) {
  const { isAuthReady, user } = useAppState();

  const renderChildren = user || !process.env.REACT_APP_SET_AUTH;

  if (!renderChildren && !isAuthReady) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
