import React from 'react';

import { useAppState } from '@jaksmok/lovelanguage-common';
import { ButtonBase } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import logo from 'assets/Logo/360Live-horizental.svg';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';

import UserAvatar from '../UserAvatar';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#fff',
    boxShadow: 'inset 0px -1px 0px rgba(9, 28, 43, 0.08)',
    offset: theme.mixins.toolbar,
  },
}));

export default function LLHeader({ className }) {
  const classes = useStyles();
  const { user } = useAppState();
  const history = useHistory();

  return (
    <AppBar className={clsx(classes.appBar, className)} position="sticky">
      <Toolbar>
        <Grid container>
          <Grid item xs={6}>
            <Link to="/">
              <img src={logo} alt="360Live logo" height={50} />
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" p={1}>
              {user && (
                <ButtonBase centerRipple onClick={() => history.push('/update-profile')}>
                  <UserAvatar user={user} />
                </ButtonBase>
              )}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
