import React from 'react';

export default function MailSentIcon() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.6214 92.7775H15.9083C15.7592 92.7777 15.6115 92.7485 15.4736 92.6916C15.3358 92.6346 15.2106 92.5511 15.1051 92.4457C14.9995 92.3403 14.9158 92.2152 14.8587 92.0774C14.8016 91.9397 14.7722 91.792 14.7722 91.6429C14.7722 91.4938 14.8016 91.3461 14.8587 91.2084C14.9158 91.0706 14.9995 90.9455 15.1051 90.8401C15.2106 90.7347 15.3358 90.6512 15.4736 90.5942C15.6115 90.5373 15.7592 90.5081 15.9083 90.5083H24.6214C24.7705 90.5081 24.9182 90.5373 25.056 90.5942C25.1938 90.6512 25.3191 90.7347 25.4246 90.8401C25.5301 90.9455 25.6138 91.0706 25.6709 91.2084C25.728 91.3461 25.7574 91.4938 25.7574 91.6429C25.7574 91.792 25.728 91.9397 25.6709 92.0774C25.6138 92.2152 25.5301 92.3403 25.4246 92.4457C25.3191 92.5511 25.1938 92.6346 25.056 92.6916C24.9182 92.7485 24.7705 92.7777 24.6214 92.7775Z"
        fill="#0088ED"
      />
      <path
        d="M113.505 48.7123C113.466 48.7124 113.429 48.7006 113.398 48.6787L61.5099 12.5426C61.352 12.4332 61.1644 12.3747 60.9722 12.375C60.7801 12.3753 60.5927 12.4344 60.4351 12.5444L8.92115 48.6784C8.8804 48.7069 8.82997 48.7182 8.78095 48.7096C8.73193 48.701 8.68834 48.6732 8.65976 48.6325C8.63118 48.5917 8.61995 48.5413 8.62856 48.4923C8.63716 48.4433 8.66489 48.3997 8.70563 48.3711L60.2197 12.2372C60.4402 12.0832 60.7026 12.0004 60.9716 12C61.2406 11.9996 61.5033 12.0815 61.7244 12.2347L113.612 48.3707C113.645 48.3936 113.67 48.4263 113.682 48.4641C113.695 48.5019 113.696 48.5428 113.684 48.581C113.672 48.6191 113.648 48.6524 113.616 48.676C113.584 48.6996 113.545 48.7124 113.505 48.7123L113.505 48.7123Z"
        fill="#3F3D56"
      />
      <path
        d="M11.8645 49.9932L61.023 13.5608L110.554 52.5856L63.5558 80.447L38.0396 74.6308L11.8645 49.9932Z"
        fill="#E6E6E6"
      />
      <path
        d="M40.1938 97.6562H15.9083C15.7592 97.6564 15.6115 97.6272 15.4736 97.5702C15.3358 97.5133 15.2106 97.4297 15.1051 97.3244C14.9995 97.219 14.9158 97.0938 14.8587 96.9561C14.8016 96.8183 14.7722 96.6707 14.7722 96.5216C14.7722 96.3724 14.8016 96.2248 14.8587 96.087C14.9158 95.9493 14.9995 95.8241 15.1051 95.7188C15.2106 95.6134 15.3358 95.5298 15.4736 95.4729C15.6115 95.416 15.7592 95.3868 15.9083 95.387H40.1938C40.3429 95.3868 40.4906 95.416 40.6284 95.4729C40.7662 95.5298 40.8915 95.6134 40.997 95.7188C41.1025 95.8241 41.1862 95.9493 41.2433 96.087C41.3004 96.2248 41.3298 96.3724 41.3298 96.5216C41.3298 96.6707 41.3004 96.8183 41.2433 96.9561C41.1862 97.0938 41.1025 97.219 40.997 97.3244C40.8915 97.4297 40.7662 97.5133 40.6284 97.5702C40.4906 97.6272 40.3429 97.6564 40.1938 97.6562V97.6562Z"
        fill="#0088ED"
      />
      <path
        d="M61.7142 70.2857C61.5296 70.2859 61.3468 70.2501 61.1759 70.1803L30.8584 57.6006V18.2242C30.8588 17.876 30.9973 17.5422 31.2435 17.296C31.4897 17.0498 31.8235 16.9113 32.1717 16.9109H90.3337C90.6819 16.9113 91.0157 17.0498 91.2619 17.296C91.5081 17.5422 91.6466 17.876 91.647 18.2242V57.6281L91.59 57.6525L62.2688 70.1741C62.0933 70.2479 61.9047 70.2858 61.7142 70.2857Z"
        fill="white"
      />
      <path
        d="M61.7143 70.3795C61.5175 70.3797 61.3225 70.3415 61.1403 70.2672L30.7646 57.6633V18.2243C30.7651 17.8512 30.9135 17.4935 31.1773 17.2297C31.4411 16.9659 31.7987 16.8176 32.1718 16.8171H90.3337C90.7068 16.8176 91.0645 16.9659 91.3283 17.2297C91.5921 17.4935 91.7404 17.8512 91.7409 18.2243V57.6901L62.3057 70.2604C62.1185 70.3392 61.9174 70.3797 61.7143 70.3795V70.3795ZM31.1399 57.4127L61.2834 69.9202C61.5645 70.0343 61.8794 70.0324 62.1592 69.915L91.3656 57.4424V18.2243C91.3653 17.9507 91.2565 17.6884 91.063 17.495C90.8696 17.3015 90.6073 17.1927 90.3337 17.1924H32.1718C31.8982 17.1927 31.6359 17.3015 31.4425 17.495C31.249 17.6884 31.1402 17.9507 31.1399 18.2243L31.1399 57.4127Z"
        fill="#3F3D56"
      />
      <path
        d="M112.692 48H112.655L91.2053 57.2491L61.858 69.9038C61.7433 69.9525 61.6203 69.9779 61.4959 69.9785C61.3715 69.9792 61.2482 69.9551 61.133 69.9076L30.8553 57.2226L8.87887 48.0151L8.8453 48H8.80789C8.46114 48.0004 8.12869 48.1397 7.8835 48.3873C7.6383 48.6349 7.50038 48.9706 7.5 49.3208V106.679C7.50039 107.029 7.6383 107.365 7.8835 107.613C8.12869 107.86 8.46114 108 8.80789 108H112.692C113.039 108 113.371 107.86 113.616 107.613C113.862 107.365 114 107.029 114 106.679V49.3208C114 48.9706 113.862 48.6349 113.616 48.3873C113.371 48.1397 113.039 48.0004 112.692 48V48ZM113.626 106.679C113.626 106.929 113.528 107.169 113.353 107.346C113.177 107.523 112.94 107.623 112.692 107.623H8.80789C8.56016 107.623 8.3226 107.523 8.14742 107.346C7.97224 107.169 7.87378 106.929 7.87368 106.679V49.3208C7.87402 49.0768 7.96773 48.8424 8.13522 48.6666C8.30271 48.4908 8.53101 48.3871 8.77241 48.3774L30.8553 57.6302L60.9892 70.2566C61.3154 70.3906 61.6809 70.3886 62.0056 70.2509L91.2053 57.6585L112.73 48.3774C112.97 48.3879 113.198 48.4918 113.365 48.6675C113.532 48.8432 113.626 49.0772 113.626 49.3208V106.679Z"
        fill="#3F3D56"
      />
      <path
        d="M59.8076 52.125C59.2238 52.126 58.6555 51.9431 58.1883 51.6037L58.1594 51.5827L52.0615 47.058C51.779 46.8483 51.542 46.5868 51.3639 46.2883C51.1858 45.9898 51.0701 45.6603 51.0234 45.3185C50.9767 44.9767 51 44.6293 51.0918 44.2962C51.1837 43.9631 51.3423 43.6508 51.5587 43.3771C51.7751 43.1034 52.0449 42.8737 52.3529 42.7011C52.6609 42.5285 53.001 42.4163 53.3537 42.3711C53.7064 42.3259 54.0648 42.3484 54.4085 42.4374C54.7522 42.5264 55.0745 42.6801 55.3569 42.8898L59.3067 45.8249L68.6406 34.028C68.8571 33.7543 69.1271 33.5247 69.4351 33.3522C69.7432 33.1797 70.0833 33.0677 70.436 33.0226C70.7887 32.9775 71.1471 33.0001 71.4908 33.0892C71.8345 33.1784 72.1567 33.3322 72.439 33.542L72.381 33.6184L72.4405 33.5431C73.01 33.9673 73.3827 34.5931 73.4767 35.2832C73.5707 35.9733 73.3784 36.6714 72.9419 37.2243L61.963 51.1004C61.709 51.4201 61.3825 51.6789 61.0087 51.8566C60.6349 52.0343 60.2239 52.1261 59.8076 52.125V52.125Z"
        fill="#0088ED"
      />
    </svg>
  );
}
