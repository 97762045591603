import React, { useEffect, useState } from 'react';

import { useAppState, useConfirm, useSessionStorage } from '@jaksmok/lovelanguage-common';
import useConditionalEffect from '@jaksmok/lovelanguage-common/dist/hooks/useConditionalEffect';
import { CallSteps } from '@jaksmok/lovelanguage-common/dist/state';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import isBefore from 'date-fns/isBefore';

import { INTERPRETER_BREAK_DATE_KEY } from '../constants';

export default function useBreakTime({ isOnline, prevIsOnline, pending, handleStatusChange }) {
  const { confirm, handleClose } = useConfirm();
  const { appConfig, setCallStep, returnedFromMeeting, setMeetingReturn } = useAppState();
  const [breakEndDate, setBreakEndDate] = useSessionStorage(INTERPRETER_BREAK_DATE_KEY, null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const setAsBreak = (breakTime) => {
    setBreakEndDate(breakTime.getTime());
  };

  const setAsOnline = () => {
    // set status to online
    handleStatusChange(true);
    // reset end date
    setBreakEndDate(null);
  };

  useEffect(() => {
    if (isOnline === prevIsOnline) return;

    if (isOnline) {
      setBreakEndDate(null);
      setMeetingReturn(false);
    }
  }, [isOnline, prevIsOnline, setMeetingReturn, setBreakEndDate]);

  useConditionalEffect(() => {
    const INTERPRETER_BREAKING_TIME = appConfig?.INTERPRETER_BREAKING_TIME || 0;
    const breakTime = new Date();

    setCallStep(CallSteps._default);
    setDialogOpen(true);

    const dismissTimer = setTimeout(() => {
      setDialogOpen(false);
      setAsOnline();
      handleClose();
    }, INTERPRETER_BREAKING_TIME * 1000);

    const confirmOpts = {
      title: 'Break or Another Call?',
      description:
        'Your are now Offline and you will not receive any incoming calls. Anytime, you can set yourself Online and start receiving calls.',
      confirmationText: `Take a break`,
      cancellationText: 'Set my status Online',
      iconEl: <HelpOutlineOutlinedIcon fontSize="inherit" />,
      severity: 'info',
      variant: 'filled',
    };

    confirm(confirmOpts)
      .then(() => {
        // Interpreter takes a break
        breakTime.setSeconds(breakTime.getSeconds() + INTERPRETER_BREAKING_TIME);

        if (isBefore(new Date(), breakTime)) {
          setAsBreak(breakTime);
        } else {
          setAsOnline();
        }
      })
      .catch(() => {
        // Interpreter wants to became Online and start recieving new calls
        setAsOnline();
      })
      .finally(() => {
        setDialogOpen(false);
        clearTimeout(dismissTimer);
      });

    return () => {
      clearTimeout(dismissTimer);
    };
  }, !dialogOpen && !pending && breakEndDate === null && isOnline === false && appConfig && returnedFromMeeting);

  return { breakEndDate, setBreakEndDate };
}
