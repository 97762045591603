import { api } from '@jaksmok/lovelanguage-common';
import getBlobDuration from 'get-blob-duration';

const VideoMailService = {
  getVoiceMail: (roomId) => api.get(`/msvc/v1/rooms/${roomId}/voicemail`),
  upload: async (roomId, record) => {
    // Get signed url from server. This url is valid for only 1 hour
    const { data: signedUrl, ok } = await api.get(`/msvc/v1/rooms/${roomId}/recording/signed-url`);
    if (!ok) {
      throw new Error('cannot get signed url for upload media');
    }

    const file = new File([record], roomId, { type: record.type });
    const formData = new FormData();
    formData.append('file', file);

    // Use fetch instead of apisauce to send a cleaned request
    // Cleaned request is a request without any configs
    await fetch(signedUrl, {
      method: 'POST',
      body: formData,
    });

    // get video duration
    const durations = await getBlobDuration(record);
    await api.put(`/msvc/v1/rooms/voicemail/${roomId}/durations`, { durations: Math.round(durations) });
  },
};

export default VideoMailService;
