import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckedOutlinedIcon from 'assets/Icons/CheckedOutlinedIcon';
import Button from 'components/Buttons/LLButton';
import Password from 'components/Input/Password';
import LoginPageLayout from 'components/Layout/LoginPageLayout';
import firebase from 'firebase/app';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((_theme) => ({
  linkButton: {
    fontWeight: '600',
    fontSize: '16px',
    textDecoration: 'none',
    width: '100%',
  },
  resetPasswordLabel: {
    fontWeight: 600,
    fontSize: '24px',
    padding: '1rem 0',
  },
  checkYourEmail: {
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center',
    padding: '1rem 0',
  },
}));

export default function ResetPasswordForm() {
  const classes = useStyles();
  const { control, handleSubmit, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);

  const urlParam = new URLSearchParams(window.location.search);
  const getResetPasswordCode = urlParam.get('oobCode');

  const handleOnResetPassword = ({ confirmNewPassword }) => {
    setLoading(true);
    firebase
      .auth()
      .confirmPasswordReset(getResetPasswordCode, confirmNewPassword)
      .then(() => {
        setIsPasswordResetSuccess(true);
        setLoading(false);
      });
  };

  return (
    <LoginPageLayout fullScreen title={isPasswordResetSuccess ? null : 'Reset Password'}>
      {!isPasswordResetSuccess ? (
        <form onSubmit={handleSubmit(handleOnResetPassword)}>
          <Grid container>
            <Box item component={Grid} paddingY={1} xs={12}>
              <Controller
                name="newPassword"
                type="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error }, ref }) => (
                  <Password
                    fullWidth
                    variant="outlined"
                    value={value}
                    error={!!error}
                    control={control}
                    placeholder="Enter new password"
                    helperText={error ? error.message : null}
                    inputRef={ref}
                    type="password"
                    onChange={onChange}
                  />
                )}
                rules={{ required: 'Password is required' }}
              />
            </Box>
            <Box item component={Grid} paddingY={1} xs={12}>
              <Controller
                name="confirmNewPassword"
                type="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error }, ref }) => (
                  <Password
                    fullWidth
                    variant="outlined"
                    value={value}
                    error={!!error}
                    control={control}
                    placeholder="Confirm new password"
                    helperText={error ? error.message : null}
                    inputRef={ref}
                    type="confirmPassword"
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: 'Password is required',
                  validate: (value) => {
                    return value === getValues().newPassword || 'Password is not match';
                  },
                }}
              />
            </Box>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-around" width="100%" marginY={2}>
                <Button fullWidth variant="contained" color="primary" type="submit" loading={loading}>
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginBottom={5}>
          <CheckedOutlinedIcon />
          <Typography variant="h4" className={classes.resetPasswordLabel}>
            Password Reset
          </Typography>
          <Typography variant="body1" className={classes.checkYourEmail}>
            Your password has been successfully reset. Please sign in.
          </Typography>
          <Box display="flex" justifyContent="space-around" width="100%" marginTop={2}>
            <Link to="/sign-in" className={classes.linkButton}>
              <Button fullWidth variant="contained" color="primary">
                Sign In
              </Button>
            </Link>
          </Box>
        </Box>
      )}
    </LoginPageLayout>
  );
}
