import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export function ErrorAlert({ dismissError, error, ...props }) {
  const { message, code } = error || {};

  return (
    <GenericAlert
      {...props}
      severity="error"
      open={error !== null}
      renderMessage={
        <>
          {message}
          {Boolean(code) && <span>Error Code: {code}</span>}
        </>
      }
      onClose={dismissError}
    />
  );
}

export function SuccessAlert({ onClose, message, ...rest }) {
  return (
    <GenericAlert
      {...rest}
      severity="success"
      open={message !== null}
      renderMessage={<>{message}</>}
      onClose={onClose}
    />
  );
}

const GenericAlert = ({ open, onClose, severity, renderMessage, ...rest }) => (
  <Snackbar
    disableWindowBlurListener
    open={open}
    autoHideDuration={10000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    onClose={onClose}
    {...rest}
  >
    <Alert severity={severity} onClose={onClose}>
      {renderMessage}
    </Alert>
  </Snackbar>
);
