import React, { useRef } from 'react';

import { useEffectOnce } from '@jaksmok/lovelanguage-common';
import { Box, Typography, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  label: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
}));

export default function PopoverDropdown({ id = 'll-mouse-over-popover', label, children, openOnMount, ...props }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = useRef();

  const handleOpen = (event) => {
    setAnchorEl(event ? event.currentTarget : anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffectOnce(() => {
    if (!openOnMount) return;

    handleOpen();
  });

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        ref={anchorRef}
        component="div"
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        className={classes.label}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        {label}
      </Typography>
      <Popover
        disableRestoreFocus
        id={id}
        open={open}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        {...props}
      >
        <Box display="flex" minWidth={200}>
          {children}
        </Box>
      </Popover>
    </>
  );
}
