import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

import { useAppState, useAsyncCallback, useAsyncMemo, usePrev, StompState, logger } from '@jaksmok/lovelanguage-common';
import InterpreterService, { InterpreterStatus } from '@jaksmok/lovelanguage-common/dist/services/interpreter';
import { useHistory } from 'react-router-dom';

import useBreakTime from './useBreakTime';
import useRoomQueue from './useRoomQueue';

const log = logger.withTag('useAvailabilityStatus');
export const availabilityStatusContext = createContext(null);

export default function useAvailabilityStatus() {
  const context = useContext(availabilityStatusContext);
  if (!context) {
    throw new Error('useAvailabilityStatus must be used within the AvailabilityStatusProvider');
  }
  return context;
}

function checkIsOnline(newStatus) {
  return !(
    newStatus === InterpreterStatus.AWAY ||
    newStatus === InterpreterStatus.BUSY ||
    newStatus === InterpreterStatus.BREAK
  );
}

export function AvailabilityStatusProvider({ children }) {
  const history = useHistory();
  const { meeting, setMeeting } = useAppState();
  const [isOnline, _setStatusOnline] = useState(false);
  const prevIsOnline = usePrev(isOnline);
  const setStatusOnline = useCallback((status) => _setStatusOnline(Boolean(status)), []);

  // Retrieve interpreter's availability status and set initial state
  const [status, { pending: fetchingStatus }] = useAsyncMemo(async () => {
    if (fetchingStatus) return;
    log.debug('fetching status');

    const response = await InterpreterService.status();
    if (response.ok) {
      const newStatus = response.data;
      const onlineStatus = checkIsOnline(newStatus);
      setStatusOnline(onlineStatus);
      return onlineStatus;
    }
    return false;
  }, []);

  // Check if Interpreter has active meeting on going.
  // If yes, close the socket connection and set meeting data by API
  const [checkActiveMeeting, { pending: fetchingActiveMeeting }] = useAsyncCallback(async () => {
    if (meeting) return meeting;

    const meetingRes = await InterpreterService.getActiveMeeting();
    const _meeting = meetingRes?.data;

    if (meetingRes.ok && _meeting) {
      await deactivateStomp();
      setMeeting(_meeting);
      return _meeting;
    }

    throw new Error('No active meeting');
  }, [history, setMeeting, meeting]);

  // Socket handler for the room queue
  const { incomingCall, setIncomingCall, deactivateStomp, stompClient } = useRoomQueue({
    isOnline,
    setStatusOnline,
  });

  // Interpreter status updates
  useEffect(() => {
    (async () => {
      // stop here if there is no change with online status
      if (prevIsOnline === isOnline) return;

      // Interpreter became ONLINE
      if (isOnline && stompClient?.state === StompState.INACTIVE) {
        await checkActiveMeeting();
      }
    })();
  }, [checkActiveMeeting, isOnline, prevIsOnline, stompClient]);

  // Update interpreter availability status
  const [handleStatusChange, { pending: updatingStatus }] = useAsyncCallback(
    async (_eventOrStatus = false) => {
      const eventOrStatus = _eventOrStatus?.target ? _eventOrStatus.target.checked : _eventOrStatus;
      const newStatus = eventOrStatus ? InterpreterStatus.AVAILABLE : InterpreterStatus.AWAY;
      const response = await InterpreterService.updateStatus(newStatus);
      const onlineStatus = checkIsOnline(newStatus);

      setStatusOnline(onlineStatus);
      return response;
    },
    [setStatusOnline]
  );

  const pending = status === null || fetchingStatus || updatingStatus || fetchingActiveMeeting;

  // Break-time
  const breakTime = useBreakTime({ isOnline, prevIsOnline, pending, handleStatusChange });

  return (
    <availabilityStatusContext.Provider
      value={{
        isOnline,
        status,
        handleStatusChange,
        pending,
        incomingCall,
        setIncomingCall,
        breakTime,
      }}
    >
      {children}
    </availabilityStatusContext.Provider>
  );
}
