import { useCallback, useMemo, useRef, useState } from 'react';

import MediaTypes from '../utils/MediaTypes';

const mediaConstraints = {
  audio: false,
  video: {
    width: 604,
    height: 376,
  },
};

const useMediaRecorder = () => {
  const mediaRecorder = useRef(null);
  const [stream, setStream] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState();
  const [recordedVideoTrack, setRecordedVideoTrack] = useState();

  const [mimeTypeWithCodec, mimeType] = useMemo(() => {
    const supportedTypes = MediaTypes.getSupportedMimeTypes();
    if (!supportedTypes || supportedTypes.length === 0) {
      return [];
    }

    const mediaType = supportedTypes[0];
    return [mediaType, mediaType.split(';')[0]];
  }, []);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        const blob = new Blob([data], {
          type: mimeType,
        });
        setRecordedVideo(blob);
        setRecordedVideoTrack(window.URL.createObjectURL(blob));
      }
    },
    [mimeType]
  );

  const getMediaStream = useCallback(async () => {
    if (stream) return;

    (async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        const _stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
        setStream(_stream);
      } catch (e) {
        throw e;
      }
    })();
  }, [stream]);

  const startRecording = useCallback(() => {
    mediaRecorder.current = new MediaRecorder(stream, {
      mimeType: mimeTypeWithCodec,
    });
    mediaRecorder.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorder.current.start();
  }, [handleDataAvailable, mimeTypeWithCodec, stream]);

  const stopRecording = useCallback(() => {
    mediaRecorder.current.stop();
    stream.getTracks().forEach((it) => it.stop());
  }, [stream]);

  const disposeRecording = useCallback(() => {
    setRecordedVideo(null);
    setRecordedVideoTrack(null);
  }, []);

  return {
    recordedVideo,
    recordedVideoTrack,
    getMediaStream,
    startRecording,
    stopRecording,
    disposeRecording,
  };
};

export default useMediaRecorder;
