import {
  InterpreterService,
  InterpreterStatus,
  RoomService,
  useAppState,
  useAsyncCallback,
} from '@jaksmok/lovelanguage-common';
import { CallSteps } from '@jaksmok/lovelanguage-common/dist/state';
import { Box, Button, Fade, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      color: 'white',
      background: theme.palette.success.main,
    },
    button: {
      color: 'white',
      padding: '4px 20px',
      fontWeigth: 600,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    enterCallButton: {
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.dark,
      },
    },
    endCallButton: {
      background: theme.palette.danger.main,
      [theme.breakpoints.down('md')]: {
        marginTop: '4px',
      },
      '&:hover': {
        background: theme.palette.danger.dark,
      },
    },
  })
);

const ActiveCallStatus = () => {
  const { meeting, setMeeting, setCallStep } = useAppState();
  const history = useHistory();
  const classes = useStyles();

  const [handleEnterCall, { pending: isEntering }] = useAsyncCallback(async () => {
    await InterpreterService.updateStatus(InterpreterStatus.BUSY);
    history.replace({ pathname: '/room/' + meeting.id });
  }, [meeting?.id]);

  const [handleEndCall, { pending: isLeaving }] = useAsyncCallback(async () => {
    setCallStep(CallSteps.ratingStep);
    await InterpreterService.updateStatus(InterpreterStatus.BREAK);
    await RoomService.leaveCall(meeting.id);
    setMeeting(null, true);
  }, [meeting?.id]);

  return (
    <Fade in timeout={300}>
      <Box className={classes.container} p={3}>
        <Grid container justifyContent="center" alignItems="center">
          {/*  */}
          <Grid item xs={8}>
            <Box>
              <Typography>
                <strong>Call In Progress</strong>
              </Typography>
              <Typography>To join the call, press Enter Room or press Leave Call to cancel.</Typography>
            </Box>
          </Grid>

          {/*  */}
          <Grid item xs={4}>
            <Box display="flex" sx={{ flexWrap: 'wrap' }} justifyContent="flex-end" alignItems="center" pt={1}>
              <Button
                className={clsx(classes.button, classes.enterCallButton)}
                disabled={isEntering || isLeaving}
                onClick={handleEnterCall}
              >
                Enter Room
              </Button>
              <Box mx="4px" />
              <Button
                className={clsx(classes.button, classes.endCallButton)}
                disabled={isEntering || isLeaving}
                onClick={handleEndCall}
              >
                Leave Call
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default ActiveCallStatus;
