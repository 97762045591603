import React, { useMemo, useRef, useState } from 'react';

import { useAppState, useAsyncCallback, useEffectOnce, useLocalStorage } from '@jaksmok/lovelanguage-common';
import InterpreterService from '@jaksmok/lovelanguage-common/dist/services/interpreter';
import { formatAxiosError } from '@jaksmok/lovelanguage-common/dist/utils';
import { Backdrop, Box, Fade, Grid, Tooltip, Typography } from '@material-ui/core';
import MuiAvatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import UserIcon from 'assets/Icons/UserIcon';
import ringtoneUrl from 'assets/ringtone.mp3';
import useAvailabilityStatus from 'hooks/useAvailabilityStatus';
import { useHistory } from 'react-router-dom';

import StyledFab from './Buttons/Fab';

export const useCallStyles = makeStyles(({ palette, zIndex }) => ({
  backdrop: {
    backgroundColor: palette.background.paper,
    zIndex: zIndex.drawer + 1,
  },
  avatar: {
    backgroundColor: `#e2f1fd`,
    border: `14px solid #ebf5fd`,
    boxShadow: `0 0 0 14px #f5fafe`,
    transform: 'scale(1)',
    animation: 'pulse 2s infinite',
    width: 132,
    height: 132,
    '& > svg': {
      width: 'auto',
      height: 50,
      fill: palette.primary.main,
    },
  },
  audio: {
    visibility: 'hidden',
  },
  roomSubject: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden',
    textAlign: 'center',
  },
  // eslint-disable-next-line mui-unused-classes/unused-classes
  '@global': {
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.90)',
        boxShadow: '0 0 0 4px #f5fafeB3',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 22px #f5fafe',
      },
      '100%': {
        transform: 'scale(0.90)',
        boxShadow: '0 0 0 4px #f5fafe',
      },
    },
  },
}));

const LightTooltip = withStyles((_) => ({
  tooltip: {
    backgroundColor: 'rgba(141, 255, 160, 1)',
    color: 'rgba(9, 28, 43, 0.8)',
    fontSize: 14,
    padding: '10px 14px 10px 14px',
    marginLeft: 75,
    fontWeight: 600,
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
  },
}))(Tooltip);

export default function IncomingCall() {
  const classes = useCallStyles();
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const { incomingCall, setIncomingCall } = useAvailabilityStatus();
  const { setMeeting, setError, appConfig } = useAppState();
  const audioEl = useRef();

  const INTERPRETER_STATUS_COUNT = 'LLVideoApp-InterpreterStatusCount';
  const [interpreterStatusesCount] = useLocalStorage(INTERPRETER_STATUS_COUNT, null, {});

  const INCOMING_CALL_EXPIRATION_MS = appConfig.INCOMING_CALL_EXPIRATION * 1000;

  useEffectOnce(() => {
    setOpen(true);
    const audio = audioEl.current;

    const playAudio = () => {
      setTimeout(() => {
        audio.play().catch((_) => {});
      }, 50); // mute Safari permission error
    };
    audio.addEventListener('canplaythrough', playAudio);

    let timer1 = setTimeout(() => {
      setOpen(false);
      // Keeping each call on the interpreter screens for {INCOMING_CALL_EXPIRATION} seconds
      setIncomingCall(null);
      // stop ringing audio
      audio.pause();
    }, INCOMING_CALL_EXPIRATION_MS);

    return () => {
      audio.pause();
      audio.removeEventListener('canplaythrough', playAudio);
      clearTimeout(timer1);
    };
  });

  // Reject Call
  const [handleOnReject, { pending: rejectPending }] = useAsyncCallback(async () => {
    if (!incomingCall.id) return;

    try {
      audioEl.current.pause();
    } catch {
      //
    }

    let response;
    try {
      response = await InterpreterService.rejectMeeting(incomingCall.id);
    } catch {
      //
    }

    setIncomingCall(null);
    return response;
  }, [setIncomingCall, incomingCall]);

  const isVoiceMail = incomingCall.status === 'VOICEMAIL' || incomingCall.status === 'INTERPRETING_VOICEMAIL';

  // Accept Call
  const [handleOnAccept, { pending: acceptPending }] = useAsyncCallback(async () => {
    if (!incomingCall.id) return;

    try {
      audioEl.current.pause();
    } catch {
      //
    }

    const response = await InterpreterService.acceptMeeting(incomingCall.id);

    if (response.ok) {
      setIncomingCall(null);
      let urlPathName = isVoiceMail ? 'voicemail' : 'room';

      setMeeting(response.data);

      history.replace({
        pathname: `/${urlPathName}/${incomingCall.id}`,
        // sending a history state just to gather data back after the redirection
        // we only need that for voicemails since rooms are set by setMeeting 🤘
        state: isVoiceMail ? incomingCall : undefined,
      });

      return response;
    }

    setIncomingCall(null);
    if (response.status === 404) {
      return setError(new Error('Meeting not found.'));
    } else if (response.status === 422) {
      return setError(new Error('Meeting request expired.'));
    }

    setError(formatAxiosError(response));
  }, [history, incomingCall, setIncomingCall, setMeeting]);

  const pending = rejectPending || acceptPending;

  const callInfo = useMemo(
    () =>
      isVoiceMail
        ? {
            title: 'Voicemail',
            subtitle: incomingCall.nonLLUser?.fullName,
            roomSubject: `To ${incomingCall.llUser.fullName}`,
          }
        : {
            title: incomingCall.llUser?.fullName,
            subtitle: 'is requesting your translation service for ',
            roomSubject: incomingCall.title || 'No Subject',
          },
    [incomingCall.llUser?.fullName, incomingCall.nonLLUser?.fullName, incomingCall.title, isVoiceMail]
  );

  if (!incomingCall) return false;

  return (
    <Fade in={isOpen}>
      <Backdrop className={classes.backdrop} open={true}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio ref={audioEl} autoPlay loop className={classes.audio} src={ringtoneUrl} />

        <Box display="flex" flexDirection="column" alignItems="center">
          {/* Avatar */}
          <MuiAvatar className={classes.avatar}>
            <UserIcon />
          </MuiAvatar>

          {/* Caller Info */}
          <Box mt={3}>
            <Typography variant="h4">{callInfo.title}</Typography>
          </Box>
          <Typography color="textSecondary">{callInfo.subtitle}</Typography>
          <Box mt={7} width={1000}>
            <Typography variant="h6" className={classes.roomSubject}>
              {callInfo.roomSubject}
            </Typography>
          </Box>
          <Box mt={8} width="400px">
            <Grid container justifyContent="space-between">
              {/* Accept Button */}
              <Grid item>
                <StyledFab color="primary" aria-label="Accept Call" disabled={pending} onClick={handleOnAccept}>
                  <CallIcon />
                </StyledFab>
                <Box mt={2}>
                  <Typography variant="body2" align="center">
                    <strong>Accept Call</strong>
                  </Typography>
                </Box>
              </Grid>

              {/* Reject Button */}
              <LightTooltip
                title={`Interpreter Available ${interpreterStatusesCount?.available ?? 0}`}
                placement="top-start"
              >
                <Grid item>
                  <StyledFab color="danger" aria-label="Pass Call" disabled={pending} onClick={handleOnReject}>
                    <CallEndIcon />
                  </StyledFab>
                  <Box mt={2}>
                    <Typography variant="body2" align="center">
                      <strong>Pass Call</strong>
                    </Typography>
                  </Box>
                </Grid>
              </LightTooltip>
            </Grid>
          </Box>
        </Box>
      </Backdrop>
    </Fade>
  );
}
