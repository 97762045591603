import { useEffect } from 'react';

import { useAppState, useAsyncCallback } from '@jaksmok/lovelanguage-common';

import UserService from '../services/user';

const useVerifyProfile = () => {
  const { profile, signOut } = useAppState();
  const [verifyProfile, { error }] = useAsyncCallback(async () => {
    await UserService.verifyProfile();
  });

  useEffect(() => {
    if (profile) {
      verifyProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (error) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default useVerifyProfile;
