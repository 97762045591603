import { api } from '@jaksmok/lovelanguage-common';
import firebaseApp from '@jaksmok/lovelanguage-common/dist/utils/firebase';
import firebase from 'firebase/app';

const UserService = {
  getProfile: () => api.get('/msvc/v1/users/me'),
  updateProfile: (profile) => api.put('/msvc/v1/users/me', profile),
  verifyProfile: async () => {
    const response = await UserService.getProfile();
    const profile = response?.data;

    let error;
    if (!profile) {
      error = { code: 'auth/user-not-found' };
    } else if (profile.disabled) {
      error = { code: 'auth/user-disabled' };
    } else if (profile.status === 'PENDING') {
      error = { code: 'auth/user-pending' };
    } else if (profile.status !== 'APPROVED') {
      error = { code: 'auth/user-not-approved' };
    }

    if (error) {
      firebase.auth().signOut();
      throw error;
    }

    return profile;
  },
  updatePassword: async (currentPassword, newPassword) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebaseApp.auth().currentUser.email,
      currentPassword
    );
    await firebaseApp.auth().currentUser.reauthenticateWithCredential(credential);
    await firebaseApp.auth().currentUser.updatePassword(newPassword);
  },
};

export default UserService;
